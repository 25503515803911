
import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import {
  Col,
  Row,
  Button,
  Image,
  Icon,
  NavBar,
  IndexBar,
  IndexAnchor,
  Cell,
  Loading,
  Search,
} from "vant";
import { monitorEvent } from "@/utils/youMengMonitor";
import { Keyboard } from "swiper";
import wx from "weixin-js-sdk";
import { NavigationGuardNext, Route } from "vue-router";
@Component({
  name: "selectCity",
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [Image.name]: Image,
    [Icon.name]: Icon,
    [NavBar.name]: NavBar,
    [IndexBar.name]: IndexBar,
    [IndexAnchor.name]: IndexAnchor,
    [Cell.name]: Cell,
    [Loading.name]: Loading,
    [Search.name]: Search,
  },
})
export default class SelectCity extends Mixins(Mixin) {
  hotCitys: Array<dictionary.PlaceCityDto> = [
    {
      cityName: "全国",
      regionCode: 0,
    },
  ];
  otherCitys: dictionary.OtherCity[] = [];
  indexList: Array<string> = [];
  isLocating = false;
  cityCodeNameMap: Record<string, string> = {}; // key：可选城市编码；value：城市名称
  // 定位的城市名称，如果定位失败，则显示定位失败
  locatedCityName = "定位中";
  locatedCityCode = "";
  Cityvalue = ""; //搜索值
  searchResult = ""; //匹配搜索结果

  isAllow = false; // 定位城市是否可点击
  from: any = {};
  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): void {
    next((vm: any) => {
      vm.from = from;
      console.log(from);
    });
  }
  mounted(): void {
    window.document.title = "城市切换";
    if (
      this.ChannelModule.channel === "plat_ios" ||
      this.ChannelModule.channel === "plat_android"
    ) {
      this.jsBridge("isShowShare", "0");
    }
    this.updateData();
  }

  /**
   * 刷新页面
   */
  updateData(): void {
    this.$api.dictionaryApi.placeCity.getPlaceCities(({ data }) => {
      let hotCitys = data.hotCitys;
      if (this.hotCitys.length <= 1) {
        this.hotCitys = [...this.hotCitys, ...(hotCitys || [])];
      }
      let otherCitys = data.otherCitys;
      if (otherCitys) {
        let indexList: Array<string> = [];
        let cityCodeNameMap: Record<string, string> = {};
        otherCitys.forEach((item) => {
          let cityFirstKey = item.cityFirstKey;
          if (cityFirstKey) {
            indexList.push(cityFirstKey);
          }
          let indexCitys: Array<dictionary.PlaceCityDto> =
            item.otherCitys || [];
          indexCitys.forEach((item) => {
            let regionCode = item.regionCode;
            if (regionCode != undefined) {
              cityCodeNameMap[regionCode] = item.cityName || "";
            }
          });
        });
        this.indexList = indexList;
        this.cityCodeNameMap = cityCodeNameMap;
        this.otherCitys = otherCitys;
      } else {
        this.otherCitys = [];
      }
    });
    this.getLocation();
  }
  // 搜索框输入是出发
  updataSearch(value?: string): void {
    console.log(value);
  }
  searchCity(value: string): void {
    if (!value.length) {
      this.$toast("请输入城市名称");
      return;
    }
    let result: any = [];
    this.otherCitys.forEach((item: any) => {
      item.otherCitys.forEach((val: any) => {
        if (val.cityName.indexOf(value) !== -1) result.push(val.cityName);
      });
    });
    this.searchResult = result[0];
    if (!result.length) {
      this.$toast("暂无相关搜索结果");
      return;
    }
    this.$nextTick(() => {
      let h: any =
        Number(
          document.querySelector(".resultKey")?.getBoundingClientRect().top
        ) - 100;
      window.scrollTo(0, h);
    });
  }
  /**
   * 选择城市
   *
   * @param cityName 城市名称
   * @param cityCode 城市编码
   */
  selectCity(cityName?: string, cityCode?: string): void {
    if (this.$route.name == "classify") {
      monitorEvent("ItemList_ChangeCity", "切换一个城市", cityName || ""); // 埋点：分类页，切换一个城市
    }
    this.LocationModule.SET_city_PERSIST({ cityName, cityCode });
    this.LocationModule.SET_isChangeCity_PERSIST(true);
    this.jsBridge("cityInfo", {
      cityName: cityName,
      cityCode: cityCode,
    });

    if (this.ChannelModule.channel === "plat_wechat_miniapp") {
      // 小程序同步定位信息
      wx.miniProgram.postMessage({
        data: {
          cityName: cityName,
          cityCode: cityCode,
        },
      });
    }
    // let fromType = String(this.$route.query.fromType);
    // if (fromType && fromType == "external") {
    this.$router.replace({
      path: this.from.path,
      query: {
        ...this.from.query,
        cityName: cityName,
        cityCode: cityCode,
      },
    });
    // } else {
    //   this.$router.go(-1);
    // }
    // }
  }

  /**
   * 点选定位的城市
   * 如果定位的城市是可选城市则选座该城市，否则不选择
   */
  selectLocationCity(): void {
    if (this.isAllow) {
      this.selectCity(this.locatedCityName, this.locatedCityCode);
    }
  }

  /**
   * 定位
   */
  getLocation(): void {
    this.isLocating = true;
    this.amapLocate(
      (cityCode, cityName) => {
        this.locatedCityName = cityName;
        this.locatedCityCode = cityCode;
        this.isLocating = false;
        this.checkIsAllow();
      },
      () => {
        this.locatedCityName = "定位失败";
        this.locatedCityCode = "";
        this.isLocating = false;
        this.checkIsAllow();
      }
    );
  }
  checkIsAllow(): void {
    this.isAllow = false;
    if (this.locatedCityCode) {
      this.$api.dictionaryApi.placeCity.getPlaceCityProduct(
        this.locatedCityCode,
        ({ data }) => {
          if (data) {
            this.isAllow = true;
          }
        }
      );
    }
  }

  returnHome(): void {
    this.$router.go(-1);
  }
}
